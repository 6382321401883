import { createBrowserRouter } from "react-router-dom";
import { Suspense, lazy } from "react";
import { routes } from "./routes";
import { Redirect } from "../utils/Redirect";

const PromotionPreview = lazy(
  () => import("../pages/Promotion/PromotionPreview")
);
const CommunityLayout = lazy(
  () => import("../pages/Community/CommunityLayout")
);
const Spinner = lazy(() => import("../components/common/Spinner/Spinner"));
const ReturnStripe = lazy(() => import("../pages/ReturnStripe"));
const SponsorCongratulations = lazy(
  () => import("../pages/Sponsor/SponsorCongratulations")
);
const PromotionLayout = lazy(
  () => import("../pages/Promotion/PromotionLayout")
);
const ActivityLayout = lazy(
  () => import("../pages/ActivityDetails/ActivityLayout")
);
const Layout = lazy(() => import("../components/SponsorLayout"));
const PromotionEdit = lazy(() => import("../pages/Promotion/PromotionEdit"));
const PromotionCreate = lazy(
  () => import("../pages/Promotion/PromotionCreate")
);
const Discover = lazy(() => import("../pages/Discover"));
const MyActivities = lazy(() => import("../pages/MyActivities"));
const Login = lazy(() => import("../pages/Login"));
const EmailVerification = lazy(() => import("../pages/EmailVerification"));
const CommunityDetails = lazy(
  () => import("../pages/Community/CommunityDetails")
);
const SponsorActivityDetails = lazy(
  () => import("../pages/Sponsor/SponsorActivityDetails")
);
const SponsorPayment = lazy(() => import("../pages/Sponsor/SponsorPayment"));
const MyPastSponsored = lazy(() => import("../pages/Sponsor/MyPastSponsored"));
const MyUpComingSponsored = lazy(
  () => import("../pages/Sponsor/MyUpComingSponsored")
);
const SponsorExplore = lazy(() => import("../pages/Sponsor/SponsorExplore"));
const SponsorProfile = lazy(() => import("../pages/Sponsor/SponsorProfile"));
const SponsorUpdateProfile = lazy(
  () => import("../pages/Sponsor/SponsorUpdateProfile")
);
const SponsorOrganization = lazy(
  () => import("../pages/Sponsor/SponsorOrganization")
);
const SponsorUpdateOrganization = lazy(
  () => import("../pages/Sponsor/SponsorUpdateOrganization")
);
const EditActivity = lazy(() => import("../pages/EditActivity"));
const ActivityDetails = lazy(
  () => import("../pages/ActivityDetails/ActivityDetails")
);
const MainPageV2 = lazy(
  () => import("../components/CreateActivity/MainPageV2")
);
const IntroPage = lazy(
  () => import("../components/CreateActivity/MainPageV2/IntroPage")
);
const AIPage = lazy(() => import("../components/CreateActivity/AIPage"));
const Preview = lazy(() => import("../pages/Preview"));
const PublishActivity = lazy(() => import("../pages/PublishActivity"));
const Payment = lazy(() => import("../pages/Payment"));

const MainRoutes = createBrowserRouter([
  {
    path: "/",
    element: <Redirect />,
  },
  {
    path: routes.returnStripe,
    element: (
      <Suspense fallback={<Spinner />}>
        <div className=" font-interregular">
          <ReturnStripe />
        </div>
      </Suspense>
    ),
  },
  {
    path: routes.login,
    element: (
      <Suspense fallback={<Spinner />}>
        <div className="p-2 font-interregular">
          <Login />
        </div>
      </Suspense>
    ),
  },
  {
    path: routes.discover,
    element: (
      <Suspense fallback={<Spinner />}>
        <Discover />
      </Suspense>
    ),
  },
  {
    path: routes.myActivities,
    element: (
      <Suspense fallback={<Spinner />}>
        <MyActivities />
      </Suspense>
    ),
  },
  {
    path: "",
    element: <ActivityLayout />,
    children: [
      {
        path: routes.private,
        element: (
          <Suspense fallback={<Spinner />}>
            <EmailVerification />
          </Suspense>
        ),
      },
      {
        path: routes.activity.edit,
        element: (
          <Suspense fallback={<Spinner />}>
            <EditActivity />
          </Suspense>
        ),
      },
      {
        path: routes.activity.main,
        element: (
          <Suspense fallback={<Spinner />}>
            <ActivityDetails />
          </Suspense>
        ),
      },
      {
        path: routes.activity.manual,
        element: (
          <Suspense fallback={<Spinner />}>
            <MainPageV2 />
          </Suspense>
        ),
      },
      {
        path: routes.activity.create,
        element: (
          <Suspense fallback={<Spinner />}>
            <IntroPage />
          </Suspense>
        ),
      },
      {
        path: routes.activity.aiCreate,
        element: (
          <Suspense fallback={<Spinner />}>
            <AIPage />
          </Suspense>
        ),
      },
      {
        path: routes.activity.preview,
        element: (
          <Suspense fallback={<Spinner />}>
            <PublishActivity />
          </Suspense>
        ),
      },
      {
        path: routes.activity.previewV1,
        element: (
          <Suspense fallback={<Spinner />}>
            <Preview />
          </Suspense>
        ),
      },
      {
        path: routes.activity.payment,
        element: (
          <div className="p-2 font-interregular">
            <Suspense fallback={<Spinner />}>
              <Payment />
            </Suspense>
          </div>
        ),
      },
    ],
  },

  {
    path: routes.promotion.creationIndex,
    element: <PromotionLayout />,
    children: [
      {
        path: routes.promotion.createActivity,
        element: (
          <Suspense fallback={<Spinner />}>
            <PromotionCreate />
          </Suspense>
        ),
      },
      {
        path: routes.promotion.edit,
        element: (
          <Suspense fallback={<Spinner />}>
            <PromotionEdit />
          </Suspense>
        ),
      },
      {
        path: routes.promotion.preview,
        element: (
          <Suspense fallback={<Spinner />}>
            <PromotionPreview />
          </Suspense>
        ),
      },
    ],
  },

  {
    path: routes.sponsor.main,
    element: <Layout />,
    children: [
      {
        path: routes.sponsor.organizationUpdate,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorUpdateOrganization />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.organization,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorOrganization />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.profileUpdate,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorUpdateProfile />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.profile,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorProfile />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.explore,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorExplore />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.MyUpComingSponsored,
        element: (
          <Suspense fallback={<Spinner />}>
            <MyUpComingSponsored />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.MyPastSponsored,
        element: (
          <Suspense fallback={<Spinner />}>
            <MyPastSponsored />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.payment,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorPayment />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.congratulations,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorCongratulations />
          </Suspense>
        ),
      },
      {
        path: routes.sponsor.activity,
        element: (
          <Suspense fallback={<Spinner />}>
            <SponsorActivityDetails />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: routes.community.main,
    element: <CommunityLayout />,
    children: [
      {
        path: routes.community.communityDetails,
        element: (
          <Suspense fallback={<Spinner />}>
            <CommunityDetails />
          </Suspense>
        ),
      },
    ],
  },
]);

export default MainRoutes;
