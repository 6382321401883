export const routes = {
  promotion: {
    creationIndex: "/promotion",
    createActivity: "/promotion/create",
    preview: "/promotion/preview",
    edit: "/promotion/edit",
    publish: "/promotion/",
  },
  activity: {
    edit: "/edit",
    main: "/activity/",
    manual: "/manual/create",
    create: "/create",
    aiCreate: "/ai/create",
    preview: "/preview",
    previewV1: "/preview/v1",
    payment: "/payment",
  },
  sponsor: {
    main: "/sponsor",
    organization: "/sponsor/organization",
    organizationUpdate: "/sponsor/organization/update",
    profile: "/sponsor/profile",
    profileUpdate: "/sponsor/profile/update",
    explore: "/sponsor/explore",
    MyPastSponsored: "/sponsor/MyPastSponsored",
    MyUpComingSponsored: "/sponsor/MyUpComingSponsored",
    payment: "/sponsor/payment",
    congratulations: "/sponsor/congratulations",
    activity: "/sponsor/activity",
  },
  community: {
    main: "/community",
    communityDetails: "/community/:communityId",
  },
  detail: "/detail",
  login: "/login",
  discover: "/explore",
  myActivities: "/my-activities",
  returnStripe: "/returnStripe",
  private: "/private",
};
