import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setAuthentication } from "./lib/features/global";
// This is for test environment
const firebaseConfig = {
  apiKey: "AIzaSyAPkgjceHMtXANUd7K4es5WLRyiv0Y81V8",
  authDomain: "boonoob-test.firebaseapp.com",
  projectId: "boonoob-test",
  storageBucket: "boonoob-test.appspot.com",
  messagingSenderId: "301924150195",
  appId: "1:301924150195:web:abf4a83fdaf9b8f5edf9c6",
  measurementId: "G-C8YXMTTDBR",
};
/*
const firebaseConfig = {
 apiKey: "AIzaSyDlbooTUjozNncFMRT9pTFzL-K0bKn-OcI",
 authDomain: "boonoob-app.firebaseapp.com",
 projectId: "boonoob-app",
 storageBucket: "boonoob-app.appspot.com",
 messagingSenderId: "823048849482",
 appId: "1:823048849482:web:899488e543600e28d0beb3",
 measurementId: "G-JVEPC4YERG"
};  */
const app = initializeApp(firebaseConfig);
export const TestApp = "globalTest";
// export const TestApp = 'global'
export const storage = getStorage(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
auth.onAuthStateChanged((user) => {
  console.log(user, "signed up");
});
export const signInWithGoogle = () => {
  // signInWithRedirect(auth, googleProvider).then((res) => {
  //   console.log(res);
  //   document.location.href = "/payment";
  // })
  //   .catch((err) => {
  //     console.log(err);
  //   })
  // const dispatch = useDispatch();
  // signInWithPopup(auth,googleProvider).then((res) => {
  //   console.log(res);
  //   // doc
  // }).catch((err) => {
  //   console.log(err)
  // })
  // console.log("Im here")
  // getRedirectResult(auth).then((res) => {
  //   dispatch(setAuthentication(res));
  //   console.log(res,"here is")
  // })
  // .catch((err) => {
  //   dispatch(setAuthentication(err));
  //   console.log(err,"here was");
  // })
  // try {
  //   const res = await signInWithPopup(auth, googleProvider);
  //   console.log(res, "here is res");
  //   const user = res.user;
  //   console.log(user, "here is user");
  // } catch (err) {
  //   console.log(err, "here is error");
  // }
};

